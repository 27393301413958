import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import MenuIcon from "@material-ui/icons/Menu";
import {
  Drawer,
  Grid,
  List,
  ListItem,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Modal, Backdrop, Fade } from "@material-ui/core";
import Popover from "@material-ui/core/Popover";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "block",
    "@media(max-width:1278px)": {
      display: "none",
    },
  },
  root1: {
    display: "none",
    "@media(max-width:1278px)": {
      display: "block",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  linkButtonsDiv: {
    display: "flex",
    gap: "14px",
  },
  // logo: {
  // flexGrow: 1,
  // },

  register: {
    background: "linear-gradient(89.1deg, #00F0FF 1.02%, #1E92AA 72.36%)",
    borderRadius: "20px",
    fontFamily: "Saira Semi Condensed",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "15.2381px",
    lineHeight: "18px",
    color: "#FFFFFF",
    cursor: "pointer",
    padding: "12px 24px",
    textTransform: "none",
    minWidth: "200px",
  },
  Login: {
    // fontFamily: "Saira Semi Condensed",
    // borderRadius: "20px",
    // fontStyle: "normal",
    // fontWeight: "700",
    // fontSize: "15.2381px",
    // lineHeight: "18px",
    // color: "#FDFAFE",
    // cursor: "pointer",
    // // background: "linear-gradient(320deg, #1F7C99 0%, #15D7D7 100%)",
    // // "-webkit-background-clip": "text",
    // // "-webkit-text-fill-color": "transparent",
    // // backgroundClip: "text",
    // // textFillColor: "transparent",
    // // textTransform: "none",
    // border: '2px solid #FDFAFE',
    // minWidth: "200px"
    padding: "6px 30px",
    color: "#FFF",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "33.488px",
    borderRadius: "4.186px",
    background: "#129DFF",
    boxShadow:
      "0px 2.0930235385894775px 10.465118408203125px 4.186047077178955px rgba(0, 0, 0, 0.10)",
    textTransform: "capitalize",
  },
  linkButton: {
    fontFamily: "Saira Semi Condensed",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "27px",
    color: "#FDFAFE",
    cursor: "pointer",
    textTransform: "capitalize",
  },
  linkUrl: {
    fontFamily: "Saira Semi Condensed",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "27px",
   
    cursor: "pointer",
    textTransform: "capitalize",
  },
  linkButton1: {
    color: "#F3F3F3",
    fontFamily: "Poppins",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    textTransform: "capitalize",
  },

  registerBox: {
    display: "flex",
    gap: "20px",
  },
  drawerRight: {
    "& .MuiDrawer-paperAnchorRight": {
      background: "#161616",
      padding: "30px 20px",
    },
  },
  toolbarlogo: {
    display: "flex",
    justifyContent: "space-between",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  dropdownContainer: {
    display: "flex",
    alignItems: "center",
  },
  dropdownButton: {
    color: "#FDFAFE",
    textTransform: "capitalize",
    cursor: "pointer",
  },
  popoverContent: {
    padding: "12px",
  display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  dropdownLink: {
    fontFamily: "Saira Semi Condensed",
    fontSize: "16px",
    fontWeight: "600",
    textTransform: "capitalize",
    cursor: "pointer",
    color: "#000",
    transition: "color 0.3s ease",
    "&:hover": {
      color: "#0B1426",
    },
  },
}));

export default function TopBar() {
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openDrawerContent, setOpenDrawerContent] = useState(false);
  const [logIn, setLogIn] = useState(false);
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const handleOpenExplorerMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseExplorerMenu = () => {
    setAnchorEl(null);
  };
  const handleDrawerOpen = () => {
    setOpenDrawerContent(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawerContent(false);
  };
  const inviteCode = "";

  const token = localStorage.getItem("token");
  useEffect(() => {
    if (token) {
      setLogIn(true);
    } else setLogIn(false);
  }, [token]);
  console.log(logIn, "logIn");
  return (
    <>
      <div className={classes.root}>
        <AppBar
          position="static"
          style={{
            backgroundColor: "#0B1426",
            height: "75px",
            padding: "0px 20px",
          }}
        >
          <Toolbar className={classes.toolbar}>
            <Box>
              <img
                className={classes.logo}
                src="images/footerlogo.png"
                style={{
                  width: "134.696px",
                  height: "48px",
                  marginTop: "9px",
                  cursor: "pointer",
                }}
                onClick={() => history.push("/")}
              />
            </Box>
            <Box className={classes.linkButtonsDiv}>
              <Button
                className={classes.linkButton}
                onClick={() => history.push("/coming-soon")}
              >
                Developers
              </Button>
              <Button
                className={classes.linkButton}
                onClick={() => history.push("/wallet")}
              >
                {" "}
                Wallet
              </Button>
              <Button
                className={classes.linkButton}
                onClick={() => history.push("/Validator")}
              >
                Validators
              </Button>
              <Button
                className={classes.linkButton}
                onClick={() => history.push("/Ecosystem")}
              >
                Ecosystem
              </Button>

              <Button
                className={classes.linkButton}
                onClick={() => history.push("/ico")}
              >
                Ico
              </Button>
              {/* <Button className={classes.linkButton} onClick={() => history.push('/Community')} >Community</Button> */}

              {/* <Button className={classes.linkButton}>Whitepaper</Button> */}
              {/* <Button className={classes.linkButton}>Social Media</Button> */}
            </Box>

            <Box className={classes.registerBox}>
              <Button
                 className={classes.linkButton}
                aria-controls="explorer-menu"
                aria-haspopup="true"
                onClick={handleOpenExplorerMenu}
                
              >
                Explorer
              </Button>
            </Box>
            <Popover
              id="explorer-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleCloseExplorerMenu}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <div className={classes.popoverContent}>
                <ListItem
                  className={classes.dropdownLink}
                  onClick={() => {
                    window.open("https://testnet.chromescan.org", "_blank");
                    handleCloseExplorerMenu();
                  }}
                >
                  Testnet
                </ListItem>
                <ListItem
                  className={classes.dropdownLink}
                  onClick={() => {
                    window.open("https://mainnet.chromescan.org", "_blank");
                    handleCloseExplorerMenu();
                  }}
                >
                  Mainnet
                </ListItem>
              </div>
            </Popover>
          </Toolbar>
        </AppBar>
      </div>
      <div className={classes.root1}>
        <AppBar
          position="static"
          style={{ backgroundColor: "#0B1426", height: "67px" }}
        >
          <Toolbar className={classes.toolbarlogo}>
            <Box>
              <img
                className={classes.logo}
                src="images/footerlogo.png"
                style={{
                  width: "134.696px",
                  height: "48px",
                  marginTop: "9px",
                }}
              />
            </Box>
            <Box>
              <MenuIcon onClick={handleDrawerOpen} />
            </Box>
          </Toolbar>
          <Drawer
            anchor="right"
            open={openDrawerContent}
            onClose={handleDrawerClose}
            className={classes.drawerRight}
          >
            <Grid container>
              <Grid
                item
                xs={12}
                className={classes.gridfour}
                style={{ width: "10rem" }}
              >
                <List>
                  <img
                    className={classes.logo}
                    src="images/footerlogo.png"
                    style={{
                      width: "134.696px",
                      height: "48px",
                      marginTop: "9px",
                    }}
                  />
                  <br /> <br />
                  <ListItem className={classes.linkButton1}>
                    Developers
                  </ListItem>
                  <ListItem
                    className={classes.linkButton1}
                    onClick={() => history.push("/Validator")}
                  >
                    Validators
                  </ListItem>
                  <ListItem
                    className={classes.linkButton1}
                    onClick={() => history.push("/Ecosystem")}
                  >
                    Ecosystem
                  </ListItem>
                  <ListItem
                    className={classes.linkButton1}
                    aria-controls="explorer-menu"
                    aria-haspopup="true"
                    onClick={handleOpenExplorerMenu}
                  >
                    Explorer
                  </ListItem>
                  <ListItem
                    className={classes.linkButton1}
                    onClick={() => history.push("/ico")}
                  >
                   Ico
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Drawer>
        </AppBar>
      </div>
    </>
  );
}
