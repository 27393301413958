import React, { useState, useContext, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  Toolbar,
  makeStyles,
  IconButton,
  Dialog,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import SwipeableTemporaryDrawer from "./RightDrawer";
import Logo from "src/component/Logo";
import DialogContent from "@material-ui/core/DialogContent";
import { useHistory, useLocation } from "react-router-dom";
import SettingsContext from "src/context/SettingsContext";
import { AuthContext } from "src/context/Auth";

import { UserContext } from "src/context/User";
import Brightness4Icon from "@material-ui/icons/Brightness4";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#232B3B;",
  },
  toolbar: {
    height: 70,
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  desktopDrawer: {
    position: "absolute",
    right: 80,
    top: 30,
    width: "100%",
    borderRadius: "25px",
    height: 266,
    background: "#004AAD",
    [theme.breakpoints.down("sm")]: {
      width: 600,
      right: 0,
    },
    [theme.breakpoints.down("xs")]: {
      width: 300,
      right: 0,
    },
  },
  iconbutton: {
    color: theme.palette.secondary.main,
  },

  NotificationListStyle: {
    background: theme.palette.background.Notification,
    color: theme.palette.text.NotificationColor,
  },
  navbarIcon: {
    display: "flex",
  },
  linkcolor: {
    color: "#fff",
    fontFamily: "Saira Semi Condensed",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16.6667px",
    lineHeight: "29px",
  },
  iconColor: {
    "@media(max-width: 767px)": {
      display: "none",
    },
    "& .MuiSvgIcon-root": {
      color: "#fff !important",
    },
  },
  search: {
    width: "303px",
    height: "42px",
    borderRadius: "42px",
    background: "#0B1426",
  },
  TextBox: {
    width: "303px",
    height: "42px",
    borderRadius: "42px",
    paddingRight: 14,
    background: "#0B1426",
    fontSize: "16px",
    caretColor: "#fff",
    "&::placeholder": {
      color: "#6C757D",
    },
    "& .MuiInputBase-input": {
      color: "#fff !important",
      height: "30px",
      paddingLeft: "14px",
      paddingTop: "6px",
      paddingRight: "10px",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none !important",
    },
    "& .MuiIconButton-edgeEnd": {
      marginRight: "10px",
    },
    "& .MuiInputBase-root": {
      border: "1px solid #585757",
    },
    "&::before": {
      borderBottom: "none", // Remove the underline
    },
  },
  priceBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    maxWidth: "304px",
    borderRadius: "15px",
    background: "#0B1426",
    padding: "3px 25px",
    marginRight: "12px",
    "& p": {
      color: "#FFF",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "36.409px",
    },
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const themeSeeting = useContext(SettingsContext);
  return (
    <AppBar
      className={`${
        themeSeeting.settings.theme === "LIGHT"
          ? classes.rootLight
          : classes.root
      }`}
      color="default"
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <TopBarData />
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;

export function TopBarData() {
  const classes = useStyles();
  const location = useLocation();
  const auth = useContext(AuthContext);
  const themeSeeting = useContext(SettingsContext);
  const user = useContext(UserContext);
  const [unReadNotification, setUnReadNotification] = useState(0);

  const [open, setOpen] = useState(false);

  //   const getViewMyProfile = async (values) => {
  //     const token = localStorage.getItem("token");
  // console.log("sachin")
  //     try {
  //       const res = await Axios({
  //         method: "GET",
  //         url: ApiConfig.viewMyProfile,
  //         headers: { token: token },
  //       });
  //       if (res.data.statusCode === 200) {
  //         // setProfile(res.data.result);
  //       } else {
  //         // toast.error(res.data.responseMessage);
  //       }
  //     } catch (error) {
  //       // console.log(error);

  //       if (
  //         error.response.data.responseMessage === "jwt malformed"
  //       ) {
  //         history.push("/login");
  //         window.localStorage.clear();

  //         toast.error("jwt malformed")
  //       }
  //      else if (
  //         error.response.data.responseMessage ===
  //         "jwt expired"
  //       ) {
  //         history.push("/login");
  //         window.localStorage.clear();
  //         toast.error("Session  expired")
  //       }
  //     }
  //   };
  //   const token = localStorage.getItem("token");
  //   useEffect(() => {

  //     getViewMyProfile();
  //   }, [token]);
  // const readNotificationList = async () => {
  //   try {
  //     const res = await Axios({
  //       method: "GET",
  //       url: ApiConfig.readNotification,
  //       headers: {
  //         token: window.localStorage.getItem("token"),
  //       },
  //     });

  //     if (res.data.responseCode === 200) {
  //       setUnReadNotification(res?.data?.result?.unReadCount);
  //       user.NotificationDataList();
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   readNotificationList();
  // }, []);

  const [count, setCount] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location?.pathname]);

  return (
    <>
      <Logo
        style={{
          paddingTop: "0px",

          cursor: "pointer",
          width: "112px",
          paddingLeft: "35px",
        }}
      />

      <Box flexGrow={1} />
      <Box>
        <TextField
          inputProps={{ maxLength: 256 }}
          className={classes.search}
          placeholder="Search"
          InputProps={{
            className: classes.TextBox,
            endAdornment: (
              <InputAdornment position="end">
                <Box className={classes.passsec}>
                  <img
                    src="/images/general.svg"
                    style={{
                      fontSize: "20px",
                      display: "flex",
                      justifyContent: "center",
                      // color: "#1069C2",
                      alignItems: "center",
                    }}
                  />
                </Box>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box flexGrow={2} />
      <Box className={classes.priceBox}>
        <p>CUSDT</p>
        <p>1000</p>
        <p>CSC</p>
        <p>00.1</p>
      </Box>
      <Box>
        <IconButton
          onClick={() => {
            themeSeeting.settings.theme === "LIGHT"
              ? themeSeeting.saveSettings({
                  direction: "dtr",
                  responsiveFontSizes: true,
                  theme: "DARK",
                })
              : themeSeeting.saveSettings({
                  direction: "ltr",
                  responsiveFontSizes: true,
                  theme: "LIGHT",
                });
          }}
        >
          <Brightness4Icon style={{ fontSize: "36px" }} />
        </IconButton>
      </Box>
      <Box>
        <IconButton>
          <img src="images/notification.svg" alt="notification" />
        </IconButton>
      </Box>
      {/* <SwipeableTemporaryDrawer /> */}
      <Dialog
        classes={{ paper: classes.desktopDrawer }}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          {/* <NotificationList
            isLoading={isLoading}
            count={user?.unReadNotification}
            notificationList={user?.notificationList}
            popUp={open}
          /> */}
        </DialogContent>
      </Dialog>
    </>
  );
}
