const url = "https://nodepune-liquidity.mobiloitte.io";
// const url = "http://";

const ApiConfig = {
  //auth
  signup: `${url}/api/v1/user/register`,
  verifyOTP: `${url}/api/v1/user/verify`,
  resendOTP: `${url}/api/v1/user/resend`,
  login: `${url}/api/v1/user/login`,
  forgotPassword: `${url}/api/v1/user/forgot`,
  resetPassword: `${url}/api/v1/user/resetPassword`,
};
export default ApiConfig;