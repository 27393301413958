import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
import HomeLayout from "src/layouts/HomeLayout";
import LoginLayout from "src/layouts/LoginLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/home/home")),
  },
  {
    exact: true,
    path: "/ico",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Ico")),
  },
  {
    exact: true,
    path: "/wallet",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Wallet/index")),
  },

  {
    exact: true,
    path: "/terms-condition",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/StaticContent/Termscondition")
    ),
  },
  {
    exact: true,
    path: "/privacy-policy",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/StaticContent/PrivacyPolicy")
    ),
  },
  {
    exact: true,
    path: "/Community",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Community/index")),
  },
  {
    exact: true,
    path: "/coming-soon",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/ComingSoon/index")),
  },
  {
    exact: true,
    path: "/Validator",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Validator/index")),
  },
  {
    exact: true,
    path: "/Ecosystem",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Ecosystem/index")),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
