import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, Grid, Box, Typography } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import SettingsContext from "src/context/SettingsContext";
const useStyles = makeStyles((theme) => ({
  content: {
    minHeight: "fit-content",
    width: "100%",
    maxWidth: "500px",
    overflowY: "auto",
    borderRadius: "10px",
    padding: "30px",
    color: "#1A1919",
    background: "#FFF",
    boxShadow: "3px 4px 9px 0px rgba(0, 0, 0, 0.25)",
    // height: "53vh",
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-track": {
      margin: "40px 0 40px 0",
    },
    "&::-webkit-scrollbar-thumb": {
      background:
        "#FD9F49",
      borderRadius: "20px !important",
    },
    "@media(max-width:600px)": {
      padding: "40px 20px"
    },
  },
  left: {
    height: "100%",
    "@media(max-width:959px)": {
      display: "none",
    },

  },
  mainScreenBack: {
    overflow: "hidden",
  },
  logo: {
    cursor: "pointer",
    width: "149px",
    padding: "20px",
  },
  imageside: {
    width: "100%",
    height: "100%",
    "@media (max-width: 950px)":{
      display: "none !important",
    }
  },
  firstscreen: {
    width: "100%",
    display: " flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
}));

const LoginLayout = ({ children }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const themeSeeting = React.useContext(SettingsContext);


  return (
    <Box className={classes.mainScreenBack}>
      <Grid container style={{ height: "100vh", }}>
        <Grid item xs={12} sm={12} md={7} lg={7} style={{overflow:"auto"}}>
          <img className={classes.logo}
            onClick={() => history.push("/")}
            src="images/Logonew.png"
          />
          <Box className={classes.firstscreen}>
            <Box className={classes.content}>
              {children}
               {/* <Box style={{ display: "grid", justifyContent: "center", alignItems:"end" }}>
              <Typography classname={classes.dontacctypo}>Don’t have an account? <span classname={classes.signupspan} onClick={() => { history.push("/register") }} style={{ cursor: "pointer" }}>Sign up</span></Typography>
            </Box> */}
              </Box>
           
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5} style={{height: "100%"}} className={classes.sideimagegrid}>
          <Box style={{ height: "100%" }}>
            <img className={classes.imageside} src="/images/Sideimage.png" alt="Side_Image" />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

LoginLayout.propTypes = {
  children: PropTypes.node,
};

export default LoginLayout;
